import { bool } from "yup";

export class SelectOptionModel {
  constructor(
    public label: string,
    public value: string | number,
    public additional?: string,
    public is_global?: boolean,
    public is_personal?: boolean,
    public status?: string,
  ) {
    this.label = label;
    this.value = value;
    this.additional = additional;
    this.is_global = is_global || true;
    this.is_personal = is_personal;
    this.status = status;
  }
}
